import styled from 'styled-components';

const FooterWrapper: React.FC = styled.footer`
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 80px;
	padding: 0 30px;
	overflow: hidden;
	z-index: 99;
`;

export { FooterWrapper };
