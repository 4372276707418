import styled from 'styled-components';

const HeaderWrapper: React.FC = styled.header`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 80px;
	line-height: 80px;
	z-index: 99;
`;

export { HeaderWrapper };
