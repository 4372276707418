import React from 'react';
import { Provider } from 'mobx-react';
import styled from 'styled-components';

import { aboutStore, AboutStore } from './store';

const PageWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

interface AboutPageProps {
	store?: AboutStore;
}

class AboutPage extends React.Component<AboutPageProps> {
	public render(): React.ReactNode {
		return (
			<PageWrapper>
				<h1>About Page</h1>
			</PageWrapper>
		);
	}
}

export const About = (): JSX.Element => (
	<Provider store={aboutStore}>
		<AboutPage />
	</Provider>
);
